import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageProp } from "."
import { Section } from "../components/atoms/Section"
import { Languages } from "../provider"
import { useLang } from "../components/Header/LanguageToggle/useLang"

const Title: React.FC<{ title: string }> = ({ title }) => {
  return (
    <h4 className="mt-4 text-lg font-bold leading-tight tracking-wider uppercase text-vp-blue">
      {title}
    </h4>
  )
}

const Text = {
  [Languages.EN]: (
    <div className="w-full h-full max-w-4xl pb-40 mx-auto leading-relaxed text-gray-800">
      <Title title={"Terms and Conditions (個人情報保護の方針について)"} />

      <br />
      <div className="jp-serif">
        <p>
          株式会社バンテージポイントは、当社が取得・保有し業務上使用する当社の顧客・取引関係者等の個人情報について、個人情報保護に関する法令及びその他の規範を遵守するために、以下のとおり個人情報保護方針を定め、これを実行し維持することを宣言します。
        </p>
        <br />
        <ol
          className="ml-4"
          style={{ listStyle: "decimal", listStylePosition: "outside" }}
        >
          <li>
            当社は、個人情報の入手に際し、適法かつ公正な手段によって行い、不正な方法により入手しないことはもちろん、個人情報の利用目的を特定し、当社のインターネットホームページに必要事項を公表します。加えて当社は、個人情報を間接的に入手する場合には、入手する個人情報について、提供者が本人から適正に入手したものであるかどうかを確認します。
          </li>
          <li>
            当社は、個人情報の紛失、破壊、改ざん及び漏えい等を防止するため、不正アクセス対策、コンピュータウイルス対策など適切な情報セキュリティ対策を講じます。
          </li>
          <li>
            当社は、個人情報の主体であるご本人が自己の個人情報について、開示、訂正、使用停止、消去等の権利を有していることを確認し、ご本人からこれらの要求に対して適切に対応いたします。
          </li>
          <li>
            当社は、個人情報を第三者との間で共同利用したり、業務を委託するために個人情報を第三者に預託をする場合、当該第三者について十分な調査を行い、必要な契約を締結し、その他の法令上必要な措置を講じます。
          </li>
          <li>
            当社は、個人情報の利用は、特定された利用目的の範囲内で、具体的な業務権限を付与された者のみが、業務の遂行上必要な限りにおいて行います。
          </li>
          <li>
            当社は、個人情報を第三者に提供することを原則として禁止します。
          </li>
          <li>
            当社は、個人情報の目的外利用、通常の利用場所からの持ち出し、外部への送信等個人情報の漏えい行為をしません。
          </li>
          <li>
            当社は、当社従業者に対して、業務上知り得た個人情報を不適切に第三者に知らせ、又は不当な目的に使用させません。その退職後も同様にさせることとします。
          </li>
        </ol>

        <br />
        <p>
          <Title title={"お客さまへ"} />
          <span>（個人情報に関する重要なお知らせ）</span>
        </p>
        <br />
        <p>
          下記の利用条件にご同意の上で、弊社の人材候補者として登録することを希望し、登録してください。弊社のサービスは、個人情報に関しては下記の利用条件に従って行われますので、よろしくお願い申し上げます。
        </p>
        <p>
          お客様の個人情報は、現在人材を募集している職位などをご案内させて戴くなどの当社のサービス提供のために利用させて戴きます。
        </p>
        <p>
          当社は，次に掲げる場合を除いて，あらかじめユーザーの同意を得ることなく，第三者に個人情報データを提供することはありません。ただし，個人情報保護法その他の法令で認められる場合を除きます。
        </p>

        <br />
        <ol
          className="ml-4"
          style={{ listStyle: "decimal", listStylePosition: "outside" }}
        >
          <li>法令に基づく場合</li>
          <li>
            人の生命，身体または財産の保護のために必要がある場合であって，本人の同意を得ることが困難であるとき
          </li>
          <li>
            公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって，本人の同意を得ることが困難であるとき
          </li>
          <li>
            国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって，本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
          </li>
          <li>
            <span>予め次の事項を告知あるいは公表をしている場合</span>
            <ol style={{ listStyle: "disc", listStylePosition: "inside" }}>
              <li>利用目的に第三者への提供を含むこと</li>
              <li> 第三者に提供されるデータの項目</li>
              <li>第三者への提供の手段または方法</li>
              <li>
                本人の求めに応じて個人情報データの第三者への提供を停止すること
              </li>
            </ol>
          </li>
        </ol>

        <br />
        <p>
          また、お客さまには、当社が利用目的の制限、適正取得や第三者提供の制限の禁止に違反した場合には、その個人情報の利用停止、消去、第三者提供停止を求める権利があります。
        </p>
        <p>
          なお、当社は、取得したお客様の個人情報（登録データ）をクライアント企業と共同利用することがあります。
        </p>
        <p>
          お客さまには、以上の利用条件にご同意の上で、弊社の人材候補者として登録して戴くことになります。
        </p>
        <p>
          また、お客さまには、当社が利用目的の制限、適正取得や第三者提供の制限の禁止に違反した場合には、その個人情報の利用停止、消去、第三者提供停止を求める権利があります。
        </p>
        <p>
          なお、当社は、取得したお客様の個人情報（登録データ）をクライアント企業と共同利用することがあります。
        </p>
        <p>
          お客さまには、以上の利用条件にご同意の上で、弊社の人材候補者として登録して戴くことになります。
        </p>

        <br />
        <p>
          <Title title="クライアント企業様へ" />
          <span>（個人情報に関する重要なお知らせ）</span>
        </p>
        <br />
        <p>
          下記の利用条件をよくお読みいただき、これにご同意の上で、弊社とご契約をして頂くことになります。弊社のサービスは、個人情報に関しては下記の利用条件に従って行われますので、よろしくお願い申し上げます。
        </p>
        <p>
          貴社の個人情報は、当社のサービス提供のために利用させて戴きます。募集職位に適切と弊社が判断する人材候補者に対しては、共同利用のため貴社の個人情報を当該候補者へ貴社の同意なく提供することがあります。また、データ処理を第三者に委託することがあります。これらの場合には、秘密保持契約書の締結など安全管理措置を十分に講じた上で行うことにしております。
        </p>
        <p>
          また、お客さまには当社が利用目的の制限、適正取得や第三者提供の制限の禁止に違反した場合には、その個人情報の利用停止、消去、第三者提供停止を求める権利があります。
        </p>
        <p>
          貴社には、以上の利用条件にご同意の上で、弊社とご契約をして戴くことになります。
        </p>
      </div>
    </div>
  ),
  [Languages.JP]: (
    <div
      className="w-full h-full max-w-4xl pb-40 mx-auto leading-relaxed text-gray-800"
      style={{ fontFamily: "Noto Serif JP" }}
    >
      <Title title={"個人情報保護の方針について"} />
      <br />
      <div className="jp-serif">
        <p>
          株式会社バンテージポイントは、当社が取得・保有し業務上使用する当社の顧客・取引関係者等の個人情報について、個人情報保護に関する法令及びその他の規範を遵守するために、以下のとおり個人情報保護方針を定め、これを実行し維持することを宣言します。
        </p>
        <br />
        <ol
          className="ml-4"
          style={{ listStyle: "decimal", listStylePosition: "outside" }}
        >
          <li>
            当社は、個人情報の入手に際し、適法かつ公正な手段によって行い、不正な方法により入手しないことはもちろん、個人情報の利用目的を特定し、当社のインターネットホームページに必要事項を公表します。加えて当社は、個人情報を間接的に入手する場合には、入手する個人情報について、提供者が本人から適正に入手したものであるかどうかを確認します。
          </li>
          <li>
            当社は、個人情報の紛失、破壊、改ざん及び漏えい等を防止するため、不正アクセス対策、コンピュータウイルス対策など適切な情報セキュリティ対策を講じます。
          </li>
          <li>
            当社は、個人情報の主体であるご本人が自己の個人情報について、開示、訂正、使用停止、消去等の権利を有していることを確認し、ご本人からこれらの要求に対して適切に対応いたします。
          </li>
          <li>
            当社は、個人情報を第三者との間で共同利用したり、業務を委託するために個人情報を第三者に預託をする場合、当該第三者について十分な調査を行い、必要な契約を締結し、その他の法令上必要な措置を講じます。
          </li>
          <li>
            当社は、個人情報の利用は、特定された利用目的の範囲内で、具体的な業務権限を付与された者のみが、業務の遂行上必要な限りにおいて行います。
          </li>
          <li>
            当社は、個人情報を第三者に提供することを原則として禁止します。
          </li>
          <li>
            当社は、個人情報の目的外利用、通常の利用場所からの持ち出し、外部への送信等個人情報の漏えい行為をしません。
          </li>
          <li>
            当社は、当社従業者に対して、業務上知り得た個人情報を不適切に第三者に知らせ、又は不当な目的に使用させません。その退職後も同様にさせることとします。
          </li>
        </ol>

        <br />
        <p>
          <Title title={"お客さまへ"} />
          <span>（個人情報に関する重要なお知らせ）</span>
        </p>
        <br />
        <p>
          下記の利用条件にご同意の上で、弊社の人材候補者として登録することを希望し、登録してください。弊社のサービスは、個人情報に関しては下記の利用条件に従って行われますので、よろしくお願い申し上げます。
        </p>
        <p>
          お客様の個人情報は、現在人材を募集している職位などをご案内させて戴くなどの当社のサービス提供のために利用させて戴きます。
        </p>
        <p>
          当社は，次に掲げる場合を除いて，あらかじめユーザーの同意を得ることなく，第三者に個人情報データを提供することはありません。ただし，個人情報保護法その他の法令で認められる場合を除きます。
        </p>

        <br />
        <ol
          className="ml-4"
          style={{ listStyle: "decimal", listStylePosition: "outside" }}
        >
          <li>法令に基づく場合</li>
          <li>
            人の生命，身体または財産の保護のために必要がある場合であって，本人の同意を得ることが困難であるとき
          </li>
          <li>
            公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって，本人の同意を得ることが困難であるとき
          </li>
          <li>
            国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって，本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
          </li>
          <li>
            <span>予め次の事項を告知あるいは公表をしている場合</span>
            <ol style={{ listStyle: "disc", listStylePosition: "inside" }}>
              <li>利用目的に第三者への提供を含むこと</li>
              <li> 第三者に提供されるデータの項目</li>
              <li>第三者への提供の手段または方法</li>
              <li>
                本人の求めに応じて個人情報データの第三者への提供を停止すること
              </li>
            </ol>
          </li>
        </ol>

        <br />
        <p>
          また、お客さまには、当社が利用目的の制限、適正取得や第三者提供の制限の禁止に違反した場合には、その個人情報の利用停止、消去、第三者提供停止を求める権利があります。
        </p>
        <p>
          なお、当社は、取得したお客様の個人情報（登録データ）をクライアント企業と共同利用することがあります。
        </p>
        <p>
          お客さまには、以上の利用条件にご同意の上で、弊社の人材候補者として登録して戴くことになります。
        </p>
        <p>
          また、お客さまには、当社が利用目的の制限、適正取得や第三者提供の制限の禁止に違反した場合には、その個人情報の利用停止、消去、第三者提供停止を求める権利があります。
        </p>
        <p>
          なお、当社は、取得したお客様の個人情報（登録データ）をクライアント企業と共同利用することがあります。
        </p>
        <p>
          お客さまには、以上の利用条件にご同意の上で、弊社の人材候補者として登録して戴くことになります。
        </p>

        <br />
        <p>
          <Title title="クライアント企業様へ" />
          <span>（個人情報に関する重要なお知らせ）</span>
        </p>
        <br />
        <p>
          下記の利用条件をよくお読みいただき、これにご同意の上で、弊社とご契約をして頂くことになります。弊社のサービスは、個人情報に関しては下記の利用条件に従って行われますので、よろしくお願い申し上げます。
        </p>
        <p>
          貴社の個人情報は、当社のサービス提供のために利用させて戴きます。募集職位に適切と弊社が判断する人材候補者に対しては、共同利用のため貴社の個人情報を当該候補者へ貴社の同意なく提供することがあります。また、データ処理を第三者に委託することがあります。これらの場合には、秘密保持契約書の締結など安全管理措置を十分に講じた上で行うことにしております。
        </p>
        <p>
          また、お客さまには当社が利用目的の制限、適正取得や第三者提供の制限の禁止に違反した場合には、その個人情報の利用停止、消去、第三者提供停止を求める権利があります。
        </p>
        <p>
          貴社には、以上の利用条件にご同意の上で、弊社とご契約をして戴くことになります。
        </p>
      </div>
    </div>
  ),
}

const PrivacyPolicyPage: React.FC<PageProp> = ({ location }) => {
  const lang = useLang()
  return (
    <Layout location={location}>
      <SEO title="Privacy Policy" />
      <Section>{Text[lang]}</Section>
    </Layout>
  )
}

export default PrivacyPolicyPage
